$unit-scale: 16px !default;
$spacing-unit-scale: 4px !default;

// BREAKPOINTS
$grid-breakpoints: (
  sm: 0,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
) !default;

// Z-INDEX
$z-index-lv5: 5 !default;
$z-index-lv4: 4 !default;
$z-index-lv3: 3 !default;
$z-index-lv2: 2 !default;
$z-index-lv1: 1 !default;
