@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;
@use '@styles/mixins' as mxs;

.landscape-banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-bottom: functions.spacing(0);

  // ratio is calculated based on the image crop dimensions.
  // check imageCrops variabel in FullWidthComponent
  :global(> .media-wrapper) {
    padding-bottom: 100%;

    @include breakpoints.media-breakpoint-up(md) {
      padding-bottom: 36.975397%;
    }

    @include breakpoints.media-breakpoint-up(lg) {
      padding-bottom: 40.64026%;
    }

    @include breakpoints.media-breakpoint-up(xl) {
      padding-bottom: 36.5625%;
    }

    @include breakpoints.media-breakpoint-up(xxl) {
      padding-bottom: 32.5%;
    }
  }

  :global(.terms-conditions-cta-container) {
    padding-bottom: functions.spacing(2);

    @include breakpoints.media-breakpoint-up(lg) {
      padding-bottom: functions.spacing(6);
    }
  }

  &:global {
    &.block-left-top {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: functions.spacing(12),
          $left: functions.spacing(44)
        );
      }
    }

    &.block-left-middle {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: 50%,
          $left: functions.spacing(44),
          $transform: translate(0, -50%)
        );
      }
    }

    &.block-left-bottom {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $left: functions.spacing(44),
          $bottom: functions.spacing(12)
        );
      }
    }

    &.block-center-top {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: functions.spacing(12),
          $left: 50%,
          $transform: translate(-50%, 0)
        );
      }
    }

    &.block-center-middle {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: 50%,
          $left: 50%,
          $transform: translate(-50%, -50%)
        );
      }
    }

    &.block-center-bottom {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $left: 50%,
          $bottom: functions.spacing(12),
          $transform: translate(-50%, 0)
        );
      }
    }

    &.block-right-top {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: functions.spacing(12),
          $right: functions.spacing(44)
        );
      }
    }

    &.block-right-middle {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $top: 50%,
          $right: functions.spacing(44),
          $transform: translate(0, -50%)
        );
      }
    }

    &.block-right-bottom {
      @include breakpoints.media-breakpoint-up(lg) {
        @include mxs.setElementPosition(
          $selector: ':global(.text-module)',
          $bottom: functions.spacing(12),
          $right: functions.spacing(44)
        );
      }
    }
  }

  :global(.text-module) {
    position: relative;
    width: 100%;
    @include breakpoints.media-breakpoint-up(lg) {
      width: auto;
    }
  }
}
