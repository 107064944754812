@mixin setElementPosition(
  $selector,
  $position: absolute,
  $top: unset,
  $right: unset,
  $left: unset,
  $bottom: unset,
  $transform: unset
) {
  #{$selector} {
    position: $position;
    top: $top;
    right: $right;
    left: $left;
    bottom: $bottom;
    transform: $transform;
    @content;
  }
}
