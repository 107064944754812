@use '@styles/variables' as variables;
@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/mixins/text-color' as textColor;

.terms-conditions {
  width: 100%;
  overflow: hidden;

  .terms-conditions-cta-container {
    display: flex;
    justify-content: center;
    padding-top: functions.spacing(4);
    color: var(--text-primary-dark);

    @include breakpoints.media-breakpoint-up(lg) {
      padding: functions.spacing(6);
      z-index: variables.$z-index-lv3;
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }

  .terms-conditions-cta {
    display: flex;
    align-items: center;
    gap: functions.spacing(2);
    padding: 0;
    margin: 0;
    vertical-align: initial;
    line-height: initial;
    border: none;
    background: none;
    font-size: 0.875rem;
    cursor: pointer;
    text-decoration: underline;
    appearance: none;

    &:hover {
      color: var(--text-tertiary-dark);
    }

    &:global(.default-color) {
      color: var(--text-primary-dark);

      &:hover {
        color: var(--text-primary-dark);
      }
    }

    @include breakpoints.media-breakpoint-up(lg) {
      font-weight: var(--font-weight-bold);

      @include textColor.set-text-hover-color('text-dark-primary', 'primary-dark');
      @include textColor.set-text-hover-color('text-dark-secondary', 'secondary-dark');
      @include textColor.set-text-hover-color('text-light-primary', 'primary-light');
      @include textColor.set-text-hover-color('text-light-secondary', 'secondary-light');
      @include textColor.set-text-hover-color('text-dark-primary-shadow', 'primary-dark');
      @include textColor.set-text-hover-color('text-dark-secondary-shadow', 'secondary-dark');
      @include textColor.set-text-hover-color('text-light-primary-shadow', 'primary-light');
      @include textColor.set-text-hover-color('text-light-secondary-shadow', 'secondary-light');
    }
  }

  .terms-conditions-panel {
    position: absolute;
    bottom: -1px;
    z-index: variables.$z-index-lv4;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0;

    @include breakpoints.media-breakpoint-up(lg) {
      right: 0;
    }
  }

  .terms-conditions-panel-wrapper {
    z-index: variables.$z-index-lv2;
    position: absolute;
    width: 100%;
    padding: functions.spacing(0, 4);
    border: none;
    font-size: 0.75rem;
    line-height: 1.33;
    bottom: 0;
    transform: translateY(100%);
    transition: transform 225ms cubic-bezier(0, 0, 0.2, 1);

    &:global(.is-open) {
      border: 1px solid;
      transform: translateY(0);
    }

    &:global(.primary) {
      background: var(--background-primary-dark);
      color: var(--text-primary-light);
      border: none;
    }

    &:global(.secondary) {
      background: var(--background-primary-light);
      color: var(--text-primary-dark);
      border-color: var(--border-tertiary-light);
    }

    &:global(.tertiary) {
      background: var(--background-secondary-dark);
      color: var(--text-primary-dark);
      border: none;
    }

    @include breakpoints.media-breakpoint-up(md) {
      padding: functions.spacing(6, 16);
    }

    p {
      margin: functions.spacing(3, 0);
    }
  }

  .terms-conditions-header {
    position: relative;
    display: flex;
    justify-content: center;
    padding-top: functions.spacing(4);

    @include breakpoints.media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  .terms-conditions-title {
    font-size: 1rem;
    line-height: 1.235;
    width: 100%;
    max-width: 100vw;
    margin: functions.spacing(4, auto, 0);
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding-top: functions.spacing(3);

    @include breakpoints.media-breakpoint-up(md) {
      padding-top: 0;
      justify-content: center;
    }

    &:global(.primary) {
      &::before,
      &::after {
        background-color: var(--background-primary-light);
      }
    }

    &:global(.secondary) {
      &::before,
      &::after {
        background-color: var(--background-primary-dark);
      }
    }

    &:global(.tertiary) {
      &::before,
      &::after {
        background-color: var(--background-primary-dark);
      }
    }

    &::before,
    &::after {
      content: '';
      display: flex;
      flex: 0 1 63px;
      height: 1px;
    }

    &::before {
      @include breakpoints.media-breakpoint-up(md) {
        margin-right: functions.spacing(12);
      }
    }

    &::after {
      @include breakpoints.media-breakpoint-up(md) {
        margin-left: functions.spacing(12);
      }
    }
  }

  .terms-conditions-close {
    position: absolute;
    right: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    appearance: none;
    margin: 0;
    padding: 0;
    outline: 0;
    border: none;
    cursor: pointer;
    color: inherit;
  }
}
